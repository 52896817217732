<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <OForm :class="{submitting}" @submit="submit">
    <OFormInput
      v-model="form.code"
      :disabled="submitting"
      name="code"
      required
    >
      Verification Code:
    </OFormInput>
    <OFormError :message="error"></OFormError>
    <FormButtons>
      <OFormSubmitButton :disabled="submitting">
        Confirm
      </OFormSubmitButton>
    </FormButtons>
    <p>You did not receive the call. <a href="#null" title="Resend It" @click="resend">Resend It</a></p>
    <OFormError :message="resendMesage"></OFormError>
  </OForm>
</template>

<script>
import apiForm from '../mixins/apiForm'
import OForm from '../objects/forms/OForm'
import OFormSubmitButton from '../objects/forms/OFormSubmitButton'
import FormButtons from '../objects/forms/FormButtons'
import OFormError from '../objects/forms/OFormError'
import OFormInput from '../objects/forms/OFormInput'

const getATApi = () => import('@/services/anonymousTexting')

export default {
  name: 'CSendVerificationCode',
  components: { OFormError, OFormSubmitButton, OForm, FormButtons, OFormInput },
  mixins: [apiForm],
  props: ['to'],
  data: function () {
    return {
      form: {
        code: '',
        to: this.to,
        user: this.$store.getters.getEmail,
        pass: this.$store.getters.getPassword
      },
      error: null,
      disabled: false,
      successMsg: null,
      resendMesage: ''
    }
  },
  methods: {
    submit () {
      this.$callApi((api, atApi) => atApi.sendVerificationCode(this.form), {
        onSuccess: (response) => {
          this.emitEvent(response)
        }
      })
    },
    async resend () {
      this.resendMesage = ''
      if (!this.form.user) {
        this.form.user = ''
        this.form.pass = ''
      }
      const api = await getATApi()
      const result = await api.blockNumber(this.form)
      if (result.success) {
        this.resendMesage = result.data.message
      } else {
        this.resendMesage = result.error.message
      }
    },
    emitEvent (response) {
      this.successMsg = response.data.message
      this.$emit('formSuccessSendCode', this.successMsg)
    }

  }
}
</script>

<style lang="scss">
.o-help_blocknumner {
  color: $primary;
}
</style>
