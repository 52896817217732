const lib = Promise.all([import('libphonenumber-js/core'), import('../../metadata.us.json')])

export const parse = async value => {
  const [{ parsePhoneNumberFromString }, metadata] = await lib
  const parsedUs = parsePhoneNumberFromString(value, 'US', metadata)
  if (parsedUs) {
    return parsedUs
  }
  return parsePhoneNumberFromString(value, 'BG', metadata)
}

export const parsePhone = async value => {
  const parsed = await parse(value)
  return parsed ? parsed.number : value
}

export const formatPhone = async value => {
  const parsed = await parse(value)
  if (!parsed) return value
  if (parsed.country === 'US') return parsed.formatNational()
  return parsed.formatInternational()
}
