<template>
  <div :class="classes">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'OTooltip',
  props: {
    anchor: {
      type: String,
      validator: value => ['left', 'right', 'center'].includes(value),
      default: 'center'
    }
  },
  computed: {
    classes () {
      return ['o-tooltip', `o-tooltip--${this.anchor}`]
    }
  }
}
</script>

<style lang="scss">

  $tooltip-bg: #3e474f;
  $tooltip-color: #fff;

  .o-tooltip-container {
    position: relative;
  }

  .o-tooltip {
    position: absolute;
    bottom: 100%;
    display: none;
    margin: 0.25em 0.25em 1em;
    background: $tooltip-bg;
    border-radius: 5px;
    padding: 0.75em 0.5em;
    color: $tooltip-color;
    text-transform: none;
    font-size: 1em;
    place-self: center;
    text-align: center;
    z-index: 100;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.55);

    &:before {
      border-style: solid;
      border-width: 1em 0.75em 0 0.75em;
      border-color: $tooltip-bg transparent transparent transparent;
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin: 0 0 0 -0.75em;
    }

    .o-tooltip--left &, &.o-tooltip--left {
      left: 0;
      &:before {
        left: 20%;
      }
    }

    .o-tooltip--right &, &.o-tooltip--right {
      right: 0;
      &:before {
        left: 90%;
      }
    }
  }

  .o-tooltip-trigger {
    &.o-tooltip-trigger--hover:hover,
    &:active,
    &:focus,
    &.o-tooltip-show {
      cursor: pointer;

      .o-tooltip,
      ~ .o-tooltip {
        display: block;
      }
    }
  }
</style>
