<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <OForm :class="{submitting}" @submit="submit">
    <OFormPhoneNumberInput
      v-model="form.to"
      :disabled="submitting"
      name="to"
      placeholder="10 digit number of yours"
      required
      @invalid="realValid=false"
      @valid="realValid=true"
    >
      Block your number:
      <template v-slot:tooltip>
        Your number will be blocked from our service, and you will not be able to use it for
        either receiving or initiating calls.
      </template>
    </OFormPhoneNumberInput>
    <OFormHelp class="o-help_blocknumner">
      Your number will be blocked from our service, and you will not be able to use it for
      either receiving or initiating calls.
    </OFormHelp>
    <OFormError :message="error"></OFormError>
    <FormButtons>
      <OFormSubmitButton :disabled="submitting">
        Block my number
      </OFormSubmitButton>
    </FormButtons>
  </OForm>
</template>

<script>
import apiForm from '../mixins/apiForm'
import OForm from '../objects/forms/OForm'
import OFormSubmitButton from '../objects/forms/OFormSubmitButton'
import FormButtons from '../objects/forms/FormButtons'
import OFormError from '../objects/forms/OFormError'
import OFormPhoneNumberInput from '../objects/forms/OFormPhoneNumberInput'
import OFormHelp from '../objects/forms/OFormHelp'

export default {
  name: 'CFormBlockNumber',
  components: { OFormError, OFormSubmitButton, OForm, FormButtons, OFormPhoneNumberInput, OFormHelp },
  mixins: [apiForm],
  data: function () {
    return {
      form: {
        user: this.$store.getters.getEmail,
        pass: this.$store.getters.getPassword,
        to: ''
      },
      error: null,
      disabled: false,
      response: null,
      successMsg: null
    }
  },
  methods: {
    submit () {
      this.$emit('formSubmit')
      if (!this.form.user) {
        this.form.user = ''
        this.form.pass = ''
      }
      this.$callApi((api, atApi) => atApi.blockNumber(this.form), {
        onSuccess: (response) => {
          this.emitEvent(response)
        }
      })
    },
    emitEvent (response) {
      const phone = this.form.to
      this.successMsg = response.data.message
      this.error = response.data.message
      this.$emit('formSuccessSubmit', phone, this.successMsg)
    }
  }
}
</script>

<style lang="scss">
.o-help_blocknumner {
  color: $primary;
}
</style>
