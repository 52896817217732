<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <OFormInput
    ref="input"
    v-bind="$attrs"
    v-on="$listeners"
    type="tel"
    :value="internalValue"
    @blur="onBlur"
    @change="onChange"
    @focus="pickerError=null"
    @input="onInput">

    <slot></slot>

    <template #tooltip>
      <slot name="tooltip"></slot>
    </template>

    <template v-if="contactPickerEnabled || contactPickerPossible" #after>
      <OButton v-if="contactPickerEnabled" type="primary" @click.native.prevent="selectContact"
               :class="pickerError ? 'o-tooltip-container o-tooltip-trigger o-tooltip-show' : ''">
        <svg aria-hidden="true" data-prefix="far" data-icon="address-book" class="svg-inline--fa fa-address-book fa-w-14" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" v-bind:class="'btn-icon'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="currentColor" d="M436 160c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-20V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h320c26.5 0 48-21.5 48-48v-48h20c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-20v-64h20c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-20v-64h20zm-68 304H48V48h320v416zM208 256c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm-89.6 128h179.2c12.4 0 22.4-8.6 22.4-19.2v-19.2c0-31.8-30.1-57.6-67.2-57.6-10.8 0-18.7 8-44.8 8-26.9 0-33.4-8-44.8-8-37.1 0-67.2 25.8-67.2 57.6v19.2c0 10.6 10 19.2 22.4 19.2z"/></svg>
        <o-tooltip v-if="pickerError" anchor="right" @click.native.prevent.stop="pickerError=null">{{ pickerError }}
        </o-tooltip>
      </OButton>
      <OButton v-if="contactPickerPossible" type="primary"
               @click.native.prevent="toggleTooltip"
               class="o-tooltip-container o-tooltip-trigger" :class="{'o-tooltip-show': contactPicketTooltipShow}">
        <svg aria-hidden="true" data-prefix="far" data-icon="address-book" class="svg-inline--fa fa-address-book fa-w-14" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" v-bind:class="'btn-icon'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="currentColor" d="M436 160c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-20V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h320c26.5 0 48-21.5 48-48v-48h20c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-20v-64h20c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-20v-64h20zm-68 304H48V48h320v416zM208 256c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm-89.6 128h179.2c12.4 0 22.4-8.6 22.4-19.2v-19.2c0-31.8-30.1-57.6-67.2-57.6-10.8 0-18.7 8-44.8 8-26.9 0-33.4-8-44.8-8-37.1 0-67.2 25.8-67.2 57.6v19.2c0 10.6 10 19.2 22.4 19.2z"/></svg>
        <o-tooltip anchor="right" @click.native.prevent.stop="hideTooltip"
                   style="width: 75vw; white-space: initial; text-align: initial">
          Your browser may support contact picker functionality as experimental option.<br/>
          <br/>
          For Mobile Safari 14.5 or newer:
          <ol style="margin-top: 0">
            <li>Open <em>Settings</em></li>
            <li>Open <em>Safari</em> settings</li>
            <li>Open <em>Advanced</em> settings</li>
            <li>Open <em>Experimental Features</em></li>
            <li>Enable <em>Contact Picker API</em></li>
            <li>Reload the page</li>
          </ol>
        </o-tooltip>
      </OButton>
    </template>
  </OFormInput>
</template>

<script>
import isPhone from '../../validators/isPhone'
import { formatPhone, parsePhone } from '../../utils/phonenumber'
import OFormInput from './OFormInput'
import OButton from '../OButton'
import OTooltip from '../OTooltip'

let lastShownTooltip = null

export default {
  name: 'OFormPhoneNumberInput',
  components: { OTooltip, OButton, OFormInput },
  inheritAttrs: false,
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      default: '',
      type: [String, Number]
    }
  },
  data: function () {
    return {
      contactPickerEnabled: false,
      contactPickerPossible: false,
      contactPicketTooltipShow: false,
      pickerError: null,
      internalValue: null
    }
  },
  methods: {
    async checkTelSupported () {
      this.contactPickerEnabled = false
      this.contactPickerPossible = false
      if (!('contacts' in navigator)) {
        const { UAParser } = await import('ua-parser-js')
        const parsed = UAParser()
        if (parsed.browser.name === 'Mobile Safari') {
          const ver = parsed.browser.version.split('.')
          if (ver[0] >= 15 || (ver[0] === '14' && ver[1] >= 5)) {
            // console.log('Detected mobile safari 14.5 or newer')
            this.contactPickerPossible = true
          }
        }
        return
      }
      try {
        const supportedProperties = await navigator.contacts.getProperties()
        this.contactPickerEnabled = supportedProperties.includes('tel')
      } catch (e) {
        // silent
      }
    },
    hideTooltip () {
      this.contactPicketTooltipShow = false
      if (lastShownTooltip === this) {
        lastShownTooltip = null
      }
    },
    toggleTooltip () {
      if (this.contactPicketTooltipShow) {
        this.hideTooltip()
      } else {
        if (lastShownTooltip) {
          lastShownTooltip.contactPicketTooltipShow = false
        }
        lastShownTooltip = this
        this.contactPicketTooltipShow = true
      }
    },
    async selectContact () {
      if (!this.contactPickerEnabled) {
        this.pickerError = 'Contact picker is not supported on this browser'
        return
      }
      try {
        this.pickerError = null
        const contacts = await navigator.contacts.select(['tel'], { multiple: false })
        if (Array.isArray(contacts) && contacts.length > 1) {
          this.pickerError = `Multiple contacts selected`
          return
        }
        const c = Array.isArray(contacts) ? contacts.shift() : contacts
        if (c) {
          const tel = Array.isArray(c.tel) ? c.tel.shift() : c.tel
          if (tel) {
            this.internalValue = tel && await formatPhone(tel)
            await this.onChange()
          } else {
            this.pickerError = `Selected contact does not have a phone number`
          }
        }
      } catch (ex) {
        if (ex.name === 'TypeError' && ex.message === 'Unable to open a contact selector') {
          this.pickerError = 'Unable to open contact selector'
        } else if (ex.name === 'InvalidStateError' && /Contacts Picker is already in use/.test(ex.message)) {
          this.pickerError = 'Contacts Picker is already in use'
        } else {
          this.pickerError = 'There was an error using the contact selector'
        }
      }
    },
    async onBlur () {
      this.internalValue = this.internalValue && await formatPhone(this.internalValue)
    },
    async onChange () {
      this.$emit('change', await parsePhone(this.internalValue))
    },
    onInput (value) {
      this.dirty = true
      this.internalValue = value
    },
    async updateFromModel () {
      this.internalValue = this.value && await formatPhone(this.value)
    }
  },
  watch: {
    async internalValue (value) {
      const valid = await isPhone(value)
      this.$refs.input.setCustomValidity(valid ? '' : 'Invalid phone number')
    },
    value () {
      this.updateFromModel()
    }
  },
  created () {
    this.updateFromModel()
    this.checkTelSupported()
  }
}
</script>

<style lang="scss" scoped>
.btn-icon {
  display: inline-block;
  height: 1.3em;
  margin: -0.3em 0;

  &:focus {
    outline: none;
  }
}
</style>
